@import '~antd/dist/antd.css';


body{
  background: url("./book_bg.jpg") no-repeat center center fixed;
  background-size:cover;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Footer {
  position:absolute;
  bottom:0px;
  width: 100%;
  display: block;
  text-align: center;
}
.Content {
  position:absolute;
  margin-top: 200px;
  font-size: 32px;
  width: 100%;
  vertical-align: center;
  /* display: block; */
  text-align: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
